import React from "react"
import { MemberstackProvider } from "@memberstack/react"

const config = { publicKey: process.env.GATSBY_MEMBERSTACK_KEY }

// eslint-disable-next-line react/display-name,react/prop-types
const wrapWithProvider = ({ element }) => {
    // Instantiating in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    return (
        <MemberstackProvider config={config}>
        {element}
        </MemberstackProvider>
    )
}

export default wrapWithProvider